// headerインタラクション
const header = document.querySelector('.l-header') as HTMLElement;

window.addEventListener('scroll', function () {
  const headerHeight = header.offsetHeight;

  if (headerHeight < (window.pageYOffset || document.documentElement.scrollTop)) {
    header.classList.add('is-fixed');
  } else {
    header.classList.remove('is-fixed');
  }
});
